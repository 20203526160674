export const requiredText = 'Поле обязательно для заполнения'
export const testMinOneLayout = 'Добвьте хотя бы 1 макет'
export const testMinOneDay = 'Выберите дни работы'
export const requiredTimeText = 'Заполните время работы'

export const weekDays = [
  {
    label: 'Понедельник',
    code: 'пн',
  },
  {
    label: 'Вторник',
    code: 'вт',
  },
  {
    label: 'Среда',
    code: 'ср',
  },
  {
    label: 'Четверг',
    code: 'чт',
  },
  {
    label: 'Пятница',
    code: 'пт',
  },
  {
    label: 'Суббота',
    code: 'сб',
  },
  {
    label: 'Воскресенье',
    code: 'вс',
  },
]

export const weekDayPatterns = [
  {
    label: 'Каждый день',
    items: 'all',
  },
  {
    label: 'Будние дни',
    items: ['пн', 'вт', 'ср', 'чт', 'пт'],
  },
  {
    label: 'Выходные дни',
    items: ['сб', 'вс'],
  },
]

export const installationEnum = {
  SELF: 'self',
  CONTRACT: 'contract',
  NOT_REQUIRED: 'not_required',
}

export const stateEnum = {
  draft: 'Черновик',
  new: 'Новая',
  prepare: 'Подготовка кейса',
  negotiate: 'Согласование',
  review: 'Согласование ЦФ',
  work: 'В работе',
  report: 'Требуется фотоотчет',
  check: 'Проверка фотоотчета',
  closed: 'Закрыта',
  canceled: 'Отменена',
  completed: 'Завершена', // статус задачи
  suspended: 'Приостановлена',
}

export const mountingEnum = {
  tape: 'Скотч',
  screw: 'Саморезы',
}

export const frameEnum = {
  metal: 'Да, металлическая рама',
  wood: 'Да, деревянная рама',
  none: 'Нет',
}

export const crudBoolean = [
  {
    id: 'false',
    name: 'Нет',
  },
  {
    id: 'true',
    name: 'Да',
  },
]

export const MASK_PHONE = `+7 ### ### ## ##`
