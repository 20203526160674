import request from '@/utils/request'

let requestAuth = null

/**
 * @param {FormData} data
 * @returns {access_token: string, refresh_token: string, expires_in: number}
 */
export async function postAuthLogin(data) {
  if (requestAuth) return await requestAuth

  try {
    requestAuth = request({
      url: `/api/oauth/token`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    })
    return await requestAuth
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    requestAuth = null
  }
}

export function postAuthPhone(data) {
  return request({
    url: `/api/oauth/code_request`,
    method: 'post',
    data,
  })
}
