<template>
  <Header />
  <div class="wrapper">
    <!-- <Loader v-if="isLoading" class="wrapper__loader" /> -->
    <!-- <router-view v-else /> -->
    <router-view />
  </div>
  <div id="modals"></div>
  <Error v-if="error" />
  <ModalSuccess v-if="success" />
</template>

<script setup>
import Error from '@/components/Error.vue'
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import ModalSuccess from '@/components/ModalSuccess.vue'
import { getToken } from '@/utils/cookie'
import useResources from '@/utils/useResources'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const error = computed(() => store.state.error)
const success = computed(() => store.state.success)

onMounted(async () => {
  // загружаем ресурсы
  if (getToken()) {
    const { loadResources } = useResources()
    await loadResources()
  }
})
</script>

<style lang="scss">
.wrapper {
  padding-top: 46px;
  padding-bottom: 20px;
  @include mobile {
    padding-top: 21px;
  }
  &__loader {
    display: flex;
    margin: 0 auto;
    max-height: 50vw;
  }
}
</style>
